var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "back-to-home rounded d-none d-sm-block"
  }, [_c('button', {
    staticClass: "btn btn-icon btn-soft-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('key-icon', {
    staticClass: "icons"
  })], 1)]), _c('section', {
    staticClass: "bg-home d-flex align-items-center"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-5 col-md-6"
  }, [_c('div', {
    staticClass: "card login-page bg-white shadow rounded border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', [_vm._m(1), _vm._m(2), _c('div', {
    staticClass: "shadow signature-pad"
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "width": "100%",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign-here")) + " ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1), _c('button', {
    staticClass: "btn btn-primary w-100",
    on: {
      "click": _vm.saveSignature
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])])])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-7 col-md-6"
  }, [_c('div', {
    staticClass: "mr-lg-5"
  }, [_c('img', {
    staticClass: "img-fluid d-block mx-auto",
    attrs: {
      "src": "/images/logo-awaris.png",
      "alt": ""
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-justify"
  }, [_vm._v(" Selamat Datang ke Sistem AWARIS Online! Sila kemukakan tandatangan anda secara digital. Tandatangan tersebut akan digunapakai pada dokumen-dokumen atas talian AWARIS."), _c('br'), _c('br'), _vm._v(" “AWARIS SENTIASA BERSAMA ANDA” ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-justify"
  }, [_c('i', [_vm._v(" Welcome to AWARIS Online System! Kindly provide us your digital signature. Your signature will be used in AWARIS online documents. "), _c('br'), _c('br'), _vm._v(" “AWARIS ALWAYS WITH YOU”")])]);
}]

export { render, staticRenderFns }